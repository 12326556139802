import "../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fshared%2Fcomponents%2FAddressAutocomplete%2Fstyles.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA61W3W7bIBi9z1MgTZM2aY6CHccpvZmirtKeYJcTARKz2oAAN06nvvsE2LGxk0yall4kwHfO93f46PIn3MAzPNEV%2BL0AQEnDLZcCAc0qbPkre1wA8JZwQVmLAFw9Lt4Xyx4DPYZyoyp8RkBIwaLz1J%2Fjih9Fwi2rDQLGamZJ%2BTjGHSrWug33nVCuGQkhEFk1tXAnR6wQgBvlzWqsj1wgsAK4sTJymN1J4sSpLV0Kq48RZu0xFTtYBLbBw0CA90ZWjfUEVioE1s5ihM49eo%2FJy1HLRtCEyEpqBD48%2B4%2FD7aWmTCMAVQuMrDgFH3bZLtulw2GiMeWN6ehDJRCA%2FqcUNjngmldnBL4Ly%2FRl1%2FA3hgDsgi4ZP5YWgbzjUJhSLo4IpKlqXWY9%2Ba1C5OggSWP%2BKSH4BJ%2Fgzh3KxlZcsCtqyJemlKcfWAsujsFLR5QORN%2FyzTrfRLjNXEWE9YWYichLZT1RSli0SV8jvyEVJtye%2B9Ur04dKnhAoOaXMy85qLHolBC6wzMyXHhkWA69bx5HPMu4jWqt2HlXG6iguGJEV98ow9AuNm3FdXfOOzqrYh7TpIL8aY%2FnhnBApLBN27PqiM%2FigWpClAWBZaxMf7Nh2XNCpxMASGi%2FPkluWGIWJF9FJYxXXAWHi7vQtneZpnuaT0qHStfcWosiKrEgjxPb%2FXutNXPihQWHsrAaxJn7KbKMrHPbSu7NpVG7HeZlX%2BV8u%2FYNPlDTauMiU5NGEmc2dodnuzoaJPGa7X%2Bjn3P1FCBw%2FIftKkpeL%2B1Onwr2s6KhEe2mtrOezeB9ymRX5Bns3PqcsJLwhfaJucIZkryhbu%2FgiNI3z4cINw%2BTiuL9XvdevNaMcA0M0YwJgQcGnmovk0q10rdrPnnLy6E4mgX86jcXad%2F7KhZ68ofNXNOy1vetiteotrz%2Bn7%2BOY0i6myb8CE6u8s5oOl8mgjjBFhxmEV7gpUwRkZLrtTL3ug9CdxfsfY1ajH%2BMIAAA%3D%22%7D"
export var autoComplete = '_161y1wd0';
export var formButton = '_161y1wd7';
export var formDesktop = '_161y1wd1';
export var formInput = '_161y1wd5';
export var formInputWrapper = '_161y1wd3';
export var formLabel = '_161y1wd4';
export var formMobile = '_161y1wd2';
export var formWarning = '_161y1wd6';
export var suggestionsGoogleLogo = '_161y1wdd';
export var suggestionsGoogleLogoWrapper = '_161y1wdc';
export var suggestionsItem = '_161y1wd9';
export var suggestionsItemCity = '_161y1wdb';
export var suggestionsItemStreet = '_161y1wda';
export var suggestionsList = '_161y1wd8';