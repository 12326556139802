// @ts-nocheck
// TODO: we need to update code and dependencies to use the new google maps api and remove the @ts-nocheck

import { GoogleMapsLocale } from "../hooks/useGoogleMapsAutocompleteSuggestions"

import { trackUserInteraction } from "./tracking/dataLayer"

export type CasavoAddress = {
  city: string
  country: string
  fullAddress: string
  lat: number
  lon: number
  province: string
  provinceInitials: string
  street: string
  streetNumber: string
  zipCode: string
}

export type GoogleMapsAddress = {
  coordinates: google.maps.LatLngLiteral
  geoCodeResult: google.maps.GeocoderResult
}

function geocodeByAddress(address: string): Promise<google.maps.GeocoderResult[]> {
  const geocoder = new window.google.maps.Geocoder()
  const OK = window.google.maps.GeocoderStatus.OK

  return new Promise((resolve, reject) => {
    geocoder.geocode({ address }, (results: any, status: any) => {
      if (status !== OK) {
        reject(status)
      }
      resolve(results)
    })
  })
}

function getLatLng(result: google.maps.GeocoderResult): Promise<google.maps.LatLngLiteral> {
  return new Promise((resolve, reject) => {
    try {
      const latLng = {
        lat: result.geometry.location.lat(),
        lng: result.geometry.location.lng(),
      }
      resolve(latLng)
    } catch (e) {
      reject(e)
    }
  })
}

function mapGoogleMapsToDorisAddess({ coordinates, geoCodeResult }: GoogleMapsAddress): CasavoAddress {
  const extract = (key: string) =>
    geoCodeResult.address_components.find((x) => {
      return x.types.includes(key)
    })?.short_name || ""

  const city = extract("locality") || extract("political")

  const provinceComponent = geoCodeResult.address_components.find((x) => {
    return x.types.includes("administrative_area_level_2")
  })

  return {
    city: city,
    country: extract("country"),
    fullAddress: geoCodeResult.formatted_address,
    lat: coordinates.lat,
    lon: coordinates.lng,
    province: provinceComponent.long_name,
    provinceInitials: provinceComponent.short_name,
    street: extract("route"),
    streetNumber: extract("street_number"),
    zipCode: extract("postal_code"),
  }
}

export function geoCodeSelection(input: string): Promise<any> {
  return new Promise((resolve) => {
    geocodeByAddress(input).then((results) => {
      const bestMatch = results[0]

      getLatLng(bestMatch).then((coordinates) => {
        const rawData = {
          coordinates,
          geoCodeResult: bestMatch,
        }

        const result = mapGoogleMapsToDorisAddess(rawData)
        resolve({ rawData, result })
      })
    })
  })
}

export function splitStreetAndCity(description: string): { city: string; street: string } {
  const components = description.split(", ")
  const cityComponents = components.splice(-3, 3)

  return { city: cityComponents.join(", "), street: components.join(", ") }
}

export const fromAddressToQueryParams = (address: CasavoAddress) => ({
  city: address.city,
  country: address.country,
  lat: String(address.lat),
  location: address.fullAddress,
  lon: String(address.lon),
  province: address.province,
  provinceInitials: address.provinceInitials,
  street: address.street,
  streetNumber: address.streetNumber,
  zipCode: address.zipCode,
})

export const fromAddressToParametrizedQueryString = (address: CasavoAddress): string => {
  const searchParams = new URLSearchParams(fromAddressToQueryParams(address))
  return searchParams.toString().replace(/\+/g, "%20")
}

export const googleMapsLocaleFromLang = (lang: Locale): GoogleMapsLocale => {
  let googleMapsLocale: GoogleMapsLocale = undefined

  switch (lang) {
    case "en":
      googleMapsLocale = undefined
      break
    case "it":
      googleMapsLocale = "it-IT"
      break
    case "fr":
      googleMapsLocale = "fr-FR"
      break
  }

  return googleMapsLocale
}

export const onSubmit = (websiteUrl: URL) => (gmapsAddress: CasavoAddress) => {
  if (gmapsAddress.fullAddress) {
    trackUserInteraction("Address", "Insert")
    window.location.href = [websiteUrl, "?", fromAddressToParametrizedQueryString(gmapsAddress)].join("")
  }
}
